import React, { useState, useEffect } from "react";
import styles from "./ActivationCode.module.scss";
import GreenButton from "./GreenButton";
import Spacer from "./Spacer";

const ActivationCode = ({ showInput = true, onClick, width = "100%" }) => {
  const [inputValue, setInputValue] = useState("");
  const [disableBtn, setDisableBtn] = useState(true);
  const [code, setCode] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const codeFromUrl = params.get("code");
    if (showInput && codeFromUrl) {
      setCode(codeFromUrl);
      setInputValue(code);
    }
  }, [showInput, code]);

  useEffect(() => {
    if (showInput) {
      setDisableBtn(inputValue.length === 0);
    } else {
      setDisableBtn(false);
    }
  }, [inputValue, showInput]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleButtonClick = () => {
    if (showInput && !inputValue) {
      return;
    }
    onClick(inputValue);
  };

  return (
    <div className={styles.ActivationCode}>
      {showInput && (
        <>
          <span className={styles.ActivationCodeSpan}>
            An activation code has been sent to the email address you provided
            in the form.
          </span>
          <Spacer height="20px" />
          <input
            type="text"
            className={styles.ActivationCodeInput}
            style={{
              width: width,
            }}
            value={inputValue}
            placeholder="activation code"
            required
            onChange={handleInputChange}
          />
        </>
      )}
      <GreenButton
        disable={disableBtn}
        onClick={handleButtonClick}
        width={width}
        text="Start Free Trial"
      />
    </div>
  );
};

export default ActivationCode;
