import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Wrapper from "./contextWrapper";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import GameBefore from "./pages/gameBefore";
import GameStart from "./pages/gameStart";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Wrapper>
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<GameBefore />}></Route> */}
        {/* <Route path="gameStart" element={<GameStart />} />
         */}
        <Route path="/" element={<GameStart />} />
      </Routes>
    </BrowserRouter>
  </Wrapper>,
);
