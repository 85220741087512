import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import French from "./lang/fr.json";
import English from "./lang/en.json";

export const Context = React.createContext();

const userLocale = navigator.language;

let lang;
if (userLocale === "en") {
  lang = English;
} else if (userLocale === "fr") {
  lang = French;
}

const Wrapper = (props) => {
  const [locale, setLocale] = useState(userLocale);
  const [messages, setMessages] = useState(lang);

  function selectLanguage(e) {
    const newLocale = e?.target?.value || e;
    setLocale(newLocale);
    if (newLocale === "en") {
      setMessages(English);
    } else if (newLocale === "fr") {
      setMessages(French);
    }
  }
  const handleIntlError = (error) => {
    if (error.code === "MISSING_TRANSLATION") {
      return;
    }
    console.error(error);
  };

  return (
    <Context.Provider value={{ locale, selectLanguage }}>
      <IntlProvider
        messages={messages}
        locale={locale}
        onError={handleIntlError}
      >
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};

export default Wrapper;
