import React from "react";

const Spacer = ({ height = "20px" }) => {
  return (
    <div
      style={{
        height: height,
        width: "100%",
      }}
    />
  );
};

export default Spacer;
