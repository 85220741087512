import CryptoJs from "crypto-js";

const generateTenDigitUUID = () => {
  var timestamp = Date.now().toString();
  var randomPart = Math.floor(Math.random() * 100000000);
  var uuid = timestamp + randomPart.toString().padStart(8, "0");
  return uuid.slice(-10);
};

function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function sortParams(params) {
  const sortedKeys = Object.keys(params).sort();
  const sortedParams = {};
  for (const key of sortedKeys) {
    sortedParams[key] = params[key];
  }
  return sortedParams;
}

function concatParams(params) {
  let result = "";
  for (const key in params) {
    result += `${key}${params[key]}`;
  }
  return result;
}

const calculateSignature = (params, secret, signMethod) => {
  const sortedParams = sortParams(params);
  let concatStr = concatParams(sortedParams);
  let sign = "";
  if (signMethod === "md5") {
    concatStr = secret + concatStr + secret;
    const hash = CryptoJs.MD5(concatStr);
    sign = hash.toString();
  } else if (signMethod === "hmac") {
    const encodedStr = CryptoJs.enc.Utf8.parse(concatStr);
    const hmac = CryptoJs.HmacMD5(encodedStr, secret);
    sign = hmac.toString();
  }
  return sign.toUpperCase();
};

export { generateTenDigitUUID, calculateSignature, getRandomIntInclusive };
