import React, { useEffect, useState } from "react";
import "./VirtualGamepad.scss";
import nipplejs from "nipplejs";
import detectResize, { clearDetectDirection } from "../utils/detectResize";

const VirtualGamepad = ({ WLCG }) => {
  let gamePadIndex = 0;
  let leftNippleManager = null;
  let rightNippleManager = null;

  let handleControlBtn = () => {
    const btnList = document.querySelectorAll(
      ".wl-plugin-vc div[data-keycode]",
    );
    btnList.forEach((circleItem) => {
      const left = circleItem.getAttribute("left");
      const top = circleItem.getAttribute("top");
      const right = circleItem.getAttribute("right");
      const bottom = circleItem.getAttribute("bottom");
      if (left) {
        circleItem.style.left = `${left}px`;
      } else if (right) {
        circleItem.style.right = `${right}px`;
      }
      if (top) {
        circleItem.style.top = `${top}px`;
      } else if (bottom) {
        circleItem.style.bottom = `${bottom}px`;
      }
      circleItem.addEventListener("touchstart", (e) => {
        const keycode = circleItem.getAttribute("data-keycode");
        if (keycode === "LT") {
          WLCG.onGamePadAxis(gamePadIndex, WLCG.AXIS_LT, 255, 0);
        } else if (keycode === "RT") {
          WLCG.onGamePadAxis(gamePadIndex, WLCG.AXIS_RT, 0, 255);
        } else {
          WLCG.OnGamePadButton(gamePadIndex, Number(keycode), WLCG.KEY_DOWN);
        }
      });

      circleItem.addEventListener("touchend", (e) => {
        const keycode = circleItem.getAttribute("data-keycode");
        if (keycode === "LT") {
          WLCG.onGamePadAxis(gamePadIndex, WLCG.AXIS_LT, 0, 0);
        } else if (keycode === "RT") {
          WLCG.onGamePadAxis(gamePadIndex, WLCG.AXIS_RT, 0, 0);
        } else {
          WLCG.OnGamePadButton(gamePadIndex, Number(keycode), WLCG.KEY_UP);
        }
        // if (e.cancelable) e.preventDefault();
      });
    });
  };

  let handleNipple = () => {
    const screenWidth = window.innerWidth,
      screenHeight = window.innerHeight;
    const isRotate = screenWidth < screenHeight;

    const leftNipple = document.querySelector(".wl-plugin-vh-left-nipple");
    const rightNipple = document.querySelector(".wl-plugin-vh-right-nipple");

    if (!leftNipple) {
      clearDetectDirection();
      return;
    }

    if (isRotate) {
      leftNipple.className =
        "wl-plugin-vh-left-nipple wl-plugin-vh-nipple-isRotate";
      rightNipple.className =
        "wl-plugin-vh-right-nipple wl-plugin-vh-nipple-isRotate";
    } else {
      leftNipple.className = "wl-plugin-vh-left-nipple";
      rightNipple.className = "wl-plugin-vh-right-nipple";
    }

    const leftNippleConfig = {
      zone: leftNipple,
      mode: "static",
      color: "white",
      size: 100,
      position: { left: "50%", bottom: "50%" },
    };

    const rightNippleConfig = {
      zone: rightNipple,
      mode: "static",
      color: "white",
      size: 75,
      position: { left: "50%", bottom: "50%" },
    };

    if (leftNippleManager) {
      leftNippleManager.destroy();
    }

    if (rightNippleManager) {
      rightNippleManager.destroy();
    }

    leftNippleManager = nipplejs.create(leftNippleConfig);

    rightNippleManager = nipplejs.create(rightNippleConfig);

    const leftDirection = { x: 0, y: 0 };
    leftNippleManager.on("move", (e, data) => {
      leftDirection.x = Math.round(data.vector.x * 32767);
      leftDirection.y = Math.round(data.vector.y * 32767);

      if (isRotate) {
        leftDirection.y =
          leftDirection.y > 0
            ? -Math.abs(leftDirection.y)
            : Math.abs(leftDirection.y);

        WLCG.onGamePadAxis &&
          WLCG.onGamePadAxis(
            gamePadIndex,
            WLCG.AXIS_LXLY,
            leftDirection.y,
            leftDirection.x,
          );
      } else {
        WLCG.onGamePadAxis &&
          WLCG.onGamePadAxis(
            gamePadIndex,
            WLCG.AXIS_LXLY,
            leftDirection.x,
            leftDirection.y,
          );
      }
    });

    leftNippleManager.on("end", () => {
      WLCG.onGamePadAxis &&
        WLCG.onGamePadAxis(gamePadIndex, WLCG.AXIS_LXLY, 0, 0);
    });

    const rightDirection = { x: 0, y: 0 };
    rightNippleManager.on("move", (e, data) => {
      rightDirection.x = Math.round(data.vector.x * 32767);
      rightDirection.y = Math.round(data.vector.y * 32767);

      if (isRotate) {
        rightDirection.y =
          rightDirection.y > 0
            ? -Math.abs(rightDirection.y)
            : Math.abs(rightDirection.y);
        WLCG.onGamePadAxis &&
          WLCG.onGamePadAxis(
            gamePadIndex,
            WLCG.AXIS_RXRY,
            rightDirection.y,
            rightDirection.x,
          );
      } else {
        WLCG.onGamePadAxis &&
          WLCG.onGamePadAxis(
            gamePadIndex,
            WLCG.AXIS_RXRY,
            rightDirection.x,
            rightDirection.y,
          );
      }
    });

    rightNippleManager.on("end", () => {
      WLCG.onGamePadAxis &&
        WLCG.onGamePadAxis(gamePadIndex, WLCG.AXIS_RXRY, 0, 0);
    });
  };

  useEffect(() => {
    handleControlBtn();
    handleNipple();
  }, []);
  return (
    <div className="wl-plugin-vc">
      <div
        className="wl-plugin-vh-btn l200 t20"
        style={{ display: "none" }}
        data-keycode="32"
      >
        BACK
      </div>
      <div
        className="wl-plugin-vh-btn r200 t20"
        style={{ display: "none" }}
        data-keycode="16"
      >
        START
      </div>

      <div
        className="wl-plugin-vh-btn l20 t30"
        style={{ display: "none" }}
        data-keycode="LT"
      >
        LT
      </div>
      <div
        className="wl-plugin-vh-btn l20 t70"
        style={{ display: "none" }}
        data-keycode="256"
      >
        LB
      </div>

      <div
        className="wl-plugin-vh-btn r20 t30"
        style={{ display: "none" }}
        data-keycode="RT"
      >
        RT
      </div>
      <div
        className="wl-plugin-vh-btn r20 t70"
        style={{ display: "none" }}
        data-keycode="512"
      >
        RB
      </div>

      <div
        className="wl-plugin-vh-btn l20 t180"
        style={{ display: "none" }}
        data-keycode="64"
      >
        LS
      </div>
      <div
        className="wl-plugin-vh-btn r20 t180"
        style={{ display: "none" }}
        data-keycode="128"
      >
        RS
      </div>

      <div className="wl-plugin-vh-left-nipple"></div>
      <div
        className="wl-plugin-vh-right-nipple"
        style={{ display: "none" }}
      ></div>

      <div
        className="wl-plugin-vh-directionlist-left"
        style={{ display: "none" }}
      >
        {/* <!-- UP --> */}
        <div className="wl-plugin-vh-cross-item" data-keycode="1">
          U
        </div>
        {/* <!-- Right --> */}
        <div className="wl-plugin-vh-cross-item" data-keycode="8">
          R
        </div>
        {/* <!-- Left --> */}
        <div className="wl-plugin-vh-cross-item" data-keycode="4">
          L
        </div>
        {/* <!-- Down --> */}
        <div className="wl-plugin-vh-cross-item" data-keycode="2">
          D
        </div>
      </div>
      <div className="wl-plugin-vh-directionlist-right">
        {/* <!-- Y --> */}
        <div className="wl-plugin-vh-btn-item" data-keycode="32768">
          Y
        </div>
        {/* <!-- B --> */}
        <div className="wl-plugin-vh-btn-item" data-keycode="8192">
          B
        </div>
        {/* <!-- X --> */}
        <div className="wl-plugin-vh-btn-item" data-keycode="16384">
          X
        </div>
        {/* <!-- A --> */}
        <div className="wl-plugin-vh-btn-item" data-keycode="4096">
          A
        </div>
      </div>
    </div>
  );
};

export default VirtualGamepad;
