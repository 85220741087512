import React from "react";
import styles from "./StatisticsLog.module.scss";

const StatisticsLog = ({
  logBandWidth,
  logBitrate,
  logFps,
  logServerFps,
  logNetWorkDelay,
  logPacketLossRate,
}) => {
  return (
    <div className={styles.StatisticsLogContent}>
      <div className={styles.logItem}>
        <span className={styles.logName}>BandWidth:</span>
        <span className={styles.logData}>{logBandWidth}</span>
      </div>
      <div className={styles.logItem}>
        <span className={styles.logName}>Bitrate:</span>
        <span className={styles.logData}>{logBitrate}</span>
      </div>
      <div className={styles.logItem}>
        <span className={styles.logName}>FPS:</span>
        <span className={styles.logData}>{logFps}</span>
      </div>
      <div className={styles.logItem}>
        <span className={styles.logName}>ServerFps:</span>
        <span className={styles.logData}>{logServerFps}</span>
      </div>
      <div className={styles.logItem}>
        <span className={styles.logName}>NetWorkDelay:</span>
        <span className={styles.logData}>{logNetWorkDelay}</span>
      </div>
      <div className={styles.logItem}>
        <span className={styles.logName}>Packet loss rate:</span>
        <span className={styles.logData}>{logPacketLossRate}</span>
      </div>
    </div>
  );
};

export default StatisticsLog;
