/**
 * @typedef {Object} BitRateLevel
 * @property {number} upperLimit
 * @property {number} lowerLimit
 */

/**
 * bitRateLevel
 * @type {Object.<string, BitRateLevel>}
 */
export const bitRateLevelMap = {
  1: {
    upperLimit: 25000,
    lowerLimit: 4000,
  },
  2: {
    upperLimit: 20000,
    lowerLimit: 4000,
  },
  3: {
    upperLimit: 15000,
    lowerLimit: 3000,
  },
  4: {
    upperLimit: 10000,
    lowerLimit: 3000,
  },
  5: {
    upperLimit: 8000,
    lowerLimit: 2000,
  },
};
