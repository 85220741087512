import { http } from "./service";

function verificationCode() {
  return http("get", "/dispatch_slot");
}
function getSdkMsg(params, headers) {
  return http("post", "/schedule/dispatch-slot", params, headers);
}

export { verificationCode, getSdkMsg };
