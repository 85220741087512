import React, { useContext, useState } from "react";
import { Context } from "../contextWrapper";
import { Dropdown, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";

const LanguageSelector = () => {
  const context = useContext(Context);
  let changeLocale = (locale) => {
    setLocale(locale === "fr" ? "french" : "english");
    context.selectLanguage(locale);
  };
  const items = [
    {
      key: "1",
      label: (
        <div className="link" onClick={() => changeLocale("fr")}>
          french
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div className="link" onClick={() => changeLocale("en")}>
          english
        </div>
      ),
    },
  ];
  let [locale, setLocale] = useState("Language");

  return (
    <>
      <Space wrap>
        <Dropdown
          menu={{ items }}
          placement="bottom"
          arrow={{ pointAtCenter: true }}
          trigger={["click"]}
        >
          <span style={{ fontSize: "14px", cursor: "pointer" }}>
            <Space size="small">
              {locale}
              <DownOutlined />
            </Space>
          </span>
        </Dropdown>
      </Space>
    </>
  );
};

export default LanguageSelector;
