import React from "react";
import Spacer from "./Spacer";

const InfoMask = ({ type, closeMask }) => {
  return (
    <div
      style={{
        position: "absolute",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        color: "white",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "0 20px",
        boxSizing: "border-box",
        backdropFilter: "blur(4px)",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "10px",
          right: "20px",
          cursor: "pointer",
          display: "none",
        }}
        onClick={closeMask}
      >
        X
      </div>
      <div
        style={{
          fontWeight: "700",
          fontSize: "40px",
          color: "#FFFFFF",
        }}
      >
        Trial
        {type === "end" && <> Ended</>}
        {type === "error" && <> Error</>}
      </div>
      <Spacer height="32px" />
      <div
        style={{
          fontWeight: "400",
          fontSize: "30px",
          maxWidth: "1020px",
          textAlign: "center",
          color: "#B5BABD",
        }}
      >
        {type === "end" && (
          <>
            If you're interested in our solutions, please email us at{" "}
            <a href="mailto:contact@well-linktech.com">
              contact@well-linktech.com
            </a>{" "}
            We'll get back to you as soon as possible.
          </>
        )}

        {type === "error" && (
          <>
            {" "}
            You are in the queue. Please wait or contact us at{" "}
            <a href="mailto:contact@well-linktech.com">
              contact@well-linktech.com
            </a>{" "}
            for immediate assistance.{" "}
          </>
        )}
      </div>
    </div>

    // </div>
  );
};

export default InfoMask;
