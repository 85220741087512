import React from "react";
import styles from "./GreeButton.module.scss";

const GreenButton = ({ disabled = false, text, onClick, width = "100%" }) => {
  return (
    <button
      disabled={disabled}
      className={styles.GreenButton}
      onClick={onClick}
      style={{
        width: width,
      }}
      onMouseOver={(e) => (e.target.style.opacity = ".8")}
      onMouseOut={(e) => (e.target.style.opacity = "1")}
    >
      {text}
    </button>
  );
};

export default GreenButton;
