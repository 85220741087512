import React from "react";
import "./VirtualKeyboard.scss";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

const VirtualKeyboard = () => {
  let onChange = (input) => {
    console.debug("Input changed", input);
  };

  let onKeyPress = (button) => {
    console.debug("Button pressed", button);
  };

  return (
    <div className="VirtualKeyboardContent">
      <Keyboard onChange={onChange} onKeyPress={onKeyPress} />
    </div>
  );
};

export default VirtualKeyboard;
