import axios from "axios";
let baseURL = process.env.REACT_APP_BASE_URL || "http://default-base-url.com";

axios.defaults.timeout = 30000;
axios.defaults.baseURL = baseURL;

/**
 * http request interceptor
 */
axios.interceptors.request.use(
  (config) => {
    // let token = localStorage.getItem("_authing_token")
    // if (token) {
    //   config.headers.Authorization = `Bearer ${token}`
    // }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

/**
 * http response interceptor
 */
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error("request error:", error);
    return Promise.reject(error);
  },
);

/**
 * get
 */
export function get(url, params = {}, headers = {}) {
  return axios.get(url, { params, headers });
}

/**
 * post
 */
export function post(url, data, headers = {}) {
  return axios.post(url, data, { headers });
}

/**
 * patch
 */
export function patch(url, data = {}) {
  return axios.patch(url, data);
}

/**
 * put
 */
export function put(url, data = {}) {
  return axios.put(url, data);
}

/**
 * Generic HTTP method
 */
export function http(method, url, param, headers = {}) {
  return new Promise((resolve, reject) => {
    switch (method) {
      case "get":
        get(url, param, headers).then(resolve).catch(reject);
        break;
      case "post":
        post(url, param, headers).then(resolve).catch(reject);
        break;
      default:
        reject(new Error("Invalid method"));
    }
  });
}
