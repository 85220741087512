import React, { useEffect, useState } from "react";
import "./gameStart.scss";
import Lottie from "lottie-react";
import animationData from "../assets/infinity.json";
// import GreenButton from "../components/GreenButton";
import ActivationCode from "../components/ActivationCode.js";
import GameQueueInfo from "../components/GameQueueInfo";
import InfoMask from "../components/InfoMask";
// import { Progress } from "antd";
import DraggableIcon from "../components/DraggableIcon";
import SettingTool from "../components/SettingTool";
import StatisticsLog from "../components/StatisticsLog";
import Countdown from "../components/Countdown";
import GameError from "../components/GameError.js";
import VirtualKeyboard from "../components/VirtualKeyboard.js";
import WasdController from "../components/WasdController.js";
import VirtualGamepad from "../components/VirtualGamepad.js";
import classNames from "classnames";
import { bitRateLevelMap } from "../utils/constants.js";
import config from "../utils/config.js";
import {
  generateTenDigitUUID,
  getRandomIntInclusive,
} from "../utils/helpers.js";
import { getSdkMsg } from "../services/api.js";
// import { useSearchParams } from "react-router-dom";
import { isMobile } from "react-device-detect";

export default function Index() {
  // let [search, setSearch] = useSearchParams();
  // console.log(search.get("gameId"));
  // console.log(search.get("nodeId"));

  let uid = localStorage.getItem("user_id");
  if (!uid) {
    uid = `user_id_${generateTenDigitUUID()}`;
    localStorage.setItem("user_id", uid);
  }
  const dispatchParams = {
    bitRate: bitRateLevelMap[2].upperLimit,
    userId: uid,
    userLevel: getRandomIntInclusive(1, 4),
    userScore: getRandomIntInclusive(1, 10000),
    gameId: config.gameId,
    // gameId: search.get("gameId"),
    resolution: "1920x1080",
    fps: "60",
    codecType: 18,
  };
  const gameConfig = {
    id: "gameVideo",
    videoWidth: 1298,
    videoHeight: 1318,
    videoPoster: config.videoPoster,
    IspUrl: config.IspUrl,
    enableAudio: config.enableAudio,
    mouseShortcut: config.mouseShortcut,
    lockPoint: config.lockPoint,
    enableClipBoard: true,
    rotate: 0,
    tenantKey: config.tenantKey,
  };

  let [WLCG, setWLCG] = useState(null);
  let [isReconnect, setIsReconnect] = useState(false);
  let [showStatisticLog, setShowStatisticLog] = useState(false);
  let [showSettingTool, setShowSettingTool] = useState(true);
  let [tool, setTool] = useState(null);
  let [showVirtualKeyboard, setShowVirtualKeyboard] = useState(false);
  let [showWasdController, setShowWasdController] = useState(false);
  let [showVirtualGamepad, setShowVirtualGamepad] = useState(false);
  let [showWrite, setShowWrite] = useState(false);

  let [errorMsg, setErrorMsg] = useState("");
  let [queueMsg, setQueueMsg] = useState(false);

  let [logBandWidth, setBandWidth] = useState("");
  let [logBitrate, setBitrate] = useState("");
  let [logFps, setFps] = useState("");
  let [logServerFps, setServerFps] = useState("");
  let [logNetWorkDelay, setNetWorkDelay] = useState("");
  let [logPacketLossRate, setPacketLossRate] = useState("");

  let [step, setStep] = useState("beforeStart");
  let [showLoading, setShowLoading] = useState(false);
  let [activationCode, setActivationCode] = useState("");
  //Activation code
  let activate = (code) => {
    localStorage.setItem("activation-code", code);
    setActivationCode(code);
  };
  //Activation code
  let clearActivate = () => {
    localStorage.removeItem("activation-code");
    setActivationCode();
  };
  //Activation code
  let activateGame = (code) => {
    if (code) {
      activate(code);
    }
    startGame();
  };
  let startGame = () => {
    setStep("start");
    window.WLCG = new window.WelinkCloudGame(gameConfig);
    setWLCG(window.WLCG);
  };
  //Activation code
  useEffect(() => {
    setActivationCode(localStorage.getItem("activation-code"));
  }, []);

  useEffect(() => {
    setQueueMsg(false);
    if (step === "start") {
      setShowLoading(true);
      handleSetVideoSize();
      handleOnGameData();
      handleGameMessage();
      WLCG.init();
      initGame();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  let showTool = (bool) => {
    setShowSettingTool(bool);
  };

  let showLog = (bool) => {
    setShowStatisticLog(bool);
  };
  let showVKeyboard = () => {
    setShowVirtualKeyboard(!showVirtualKeyboard);
    if (!showVirtualKeyboard) {
      setTool(0);
      setShowVirtualGamepad(false);
      setShowWasdController(false);
    } else {
      setTool(null);
    }
  };
  let showWasd = () => {
    setShowWasdController(!showWasdController);
    if (!showWasdController) {
      setTool(1);
      setShowVirtualGamepad(false);
      setShowVirtualKeyboard(false);
    } else {
      setTool(null);
    }
  };
  let showVGamepad = () => {
    setShowVirtualGamepad(!showVirtualGamepad);
    if (!showVirtualGamepad) {
      setTool(2);
      setShowWasdController(false);
      setShowVirtualKeyboard(false);
    } else {
      setTool(null);
    }
  };
  let showW = () => {
    setShowWrite(!showWrite);
  };

  let initGame = () => {
    const bizData = WLCG.getBizData();
    const param = {
      ...dispatchParams,
      bizData,
    };
    //Activation code
    getSdkMsg(param, { "x-activation-code": activationCode })
      .then((res) => {
        if (!res?.data?.success) {
          setStep("error");
          setErrorMsg(`${res?.data?.code} - ${res?.data?.msg}`);
        } else if (
          res?.data?.data?.identiType === "queuing" &&
          res?.data?.data?.sdkMsg
        ) {
          WLCG.checkDispatchInfo(
            res.data.data.sdkMsg,
            ({ seatNo, queuingSize, sdkMsg }) => {
              if (!sdkMsg) {
                if (seatNo !== 0) {
                  setQueueMsg(
                    `Hang tight! You're in position ${seatNo} of ${queuingSize}`,
                  );
                } else {
                  setQueueMsg(
                    "You're next in line! Waiting for server resources to be allocated.",
                  );
                }
              } else {
                setQueueMsg(false);
                let sdkPayload = JSON.parse(sdkMsg);
                if (!sdkPayload.gsPort) {
                  sdkPayload.gsPort = sdkPayload.webRtcPort;
                }
                WLCG.startGame(sdkPayload);
              }
            },
            ({ code, msg }) => {
              setStep("error");
              setErrorMsg(`${code} - ${msg}`);
            },
          );
        } else if (res?.data?.data?.sdkMsg) {
          let sdkPayload = JSON.parse(res.data.data.sdkMsg);
          if (!sdkPayload.gsPort) {
            sdkPayload.gsPort = sdkPayload.webRtcPort;
          }
          WLCG.startGame(sdkPayload);
        } else {
          setStep("error");
          setErrorMsg(`API Dispatch Error`);
        }
      })
      .catch((res) => {
        //Activation code
        if (res?.response?.data?.code === 403) {
          clearActivate();
        }
        setStep("error");
        setErrorMsg(
          `${res?.response?.data?.code} - ${res?.response?.data?.msg}`,
        );
      });

    WLCG.showGameStatisticsData = (data) => {
      const {
        bandWidth,
        bitrate,
        fps,
        serverFps,
        netWorkDelayUdp,
        packetLossRate,
      } = data;
      setBandWidth(`${((bandWidth / 1000000) * 8).toFixed(2)} Mbps`);
      setBitrate(bitrate);
      setFps(fps);
      setServerFps(serverFps);
      setNetWorkDelay(`${netWorkDelayUdp} ms`);
      setPacketLossRate(`${packetLossRate} %`);
    };
  };

  // Get the game's width and height.
  const getGameScreen = () => {
    const [gameWidth, gameHeight] = dispatchParams.resolution.split("x");
    return {
      gameWidth: parseInt(gameWidth),
      gameHeight: parseInt(gameHeight),
    };
  };
  // Find the appropriate rotate value based on the game’s width and height.
  const getRotateState = () => {
    const { gameWidth, gameHeight } = getGameScreen();
    const { width: clientWidth, height: clientHeight } = document
      .querySelector("body")
      .getBoundingClientRect();
    let rotate;
    const isPortraitScreenGame = gameWidth < gameHeight;
    // Handling logic for mobile vertical screen orientation.
    if (isPortraitScreenGame) {
      if (clientWidth > clientHeight) {
        rotate = 1;
      } else {
        rotate = 0;
      }
    } else {
      if (clientWidth > clientHeight) {
        rotate = 0;
      } else {
        rotate = 1;
      }
    }
    return {
      rotate,
    };
  };
  //set the SDK rotation based on a given rotate value
  let handleSetVideoSize = () => {
    const { rotate } = getRotateState();
    if (rotate === 1) {
      if (!document.body.classList.contains("isRotate")) {
        document.body.classList.add("isRotate");
        document.body.style.width = `${window.innerHeight}px`;
      }
    } else {
      if (document.body.classList.contains("isRotate")) {
        document.body.classList.remove("isRotate");
        document.body.style.height = `${window.innerHeight}px`;
      }
    }
    WLCG.setRotate(rotate);
    WLCG.setGameScreenAdaptation(true);
  };

  /**
   * Handling the callback for receiving data sent by the game (requires integration of the data channel SDK on the game side).
   */
  const handleOnGameData = () => {
    WLCG.onGameData = (data) => {
      const result = new TextDecoder().decode(data);
      if (result === "PipeLiteIsReady") {
        const data = JSON.stringify({
          action: "login",
          uname: dispatchParams.userId,
        });
        console.debug(data, "onGameData-data");
        WLCG.sendDataToGame(data);
      }
    };
    WLCG.onGameDataWithKey = (key, data) => {
      console.debug("onGameDataWithKey-key", key);
      console.debug("onGameDataWithKey-data", data);
    };
  };
  /**
   * Handling game message callback.
   */
  const handleGameMessage = () => {
    WLCG.startGameInfo = (code, message) => {
      // console.debug(code, message, "show me message");
      // loading finish
      // Received game screen.
      if (code === 6038) {
        // Set the minimum bitrate.
        WLCG.setBitrateRange(
          bitRateLevelMap[2].lowerLimit,
          bitRateLevelMap[2].upperLimit,
        );
        setQueueMsg();
        setShowLoading(false);
        setIsReconnect(false);
        !isCountdownActive && startCountdown();
        if (isMobile) {
          setShowVirtualGamepad(true);
        }
      } else if (code === 6105) {
        setIsReconnect(true);
      }
    };

    WLCG.startGameError = (code, message) => {
      // Ignore retrieving carrier information.
      if (code === 6075 || code === 6506) return;
      if (isReconnect && code === 6041) return;
      setStep("error");
      setErrorMsg(`${code} - ${message}`);
    };
  };

  let closeGame = () => {
    if (WLCG) {
      WLCG.exitGame();
      // setWLCG(null);
      // window.WLCG = null;
      setStep("beforeStart");
    } else {
      setStep("beforeStart");
    }
  };
  let confirm = () => {
    closeGame();
  };

  let cancelQueue = () => {
    if (WLCG) {
      WLCG.cancelQueue(
        () => {
          closeGame();
        },
        ({ code, msg }) => {
          setStep("error");
          setErrorMsg(`${code} - ${msg}`);
        },
      );
    }
  };

  const [isCountdownActive, setIsCountdownActive] = useState(false);
  const startCountdown = () => {
    setIsCountdownActive(true);
  };
  // count down trigger event
  const handleCountdownEnd = () => {
    setIsCountdownActive(false);
    setInfotype("end");
    setShowInfoMask(true);
    closeGame();
  };
  let [infotype, setInfotype] = useState("error");
  let [showInfoMask, setShowInfoMask] = useState(false);
  let closeMask = () => {
    setShowInfoMask(false);
  };
  return (
    <div id="gameStartContent" className="gameStartContent">
      {step === "beforeStart" && (
        <div className="before-start">
          {!showInfoMask && (
            //Activation code
            <ActivationCode
              showInput={!activationCode}
              onClick={activateGame}
              width="auto"
            />
            //Activation code
            // <GreenButton
            //   onClick={startGame}
            //   width="auto"
            //   text="Start Free Trial"
            // />
          )}
          {showInfoMask && <InfoMask closeMask={closeMask} type={infotype} />}
        </div>
      )}

      {queueMsg && queueMsg !== "" && (
        <GameQueueInfo cancel={cancelQueue} msg={queueMsg} />
      )}

      {step === "start" && (
        <div className={classNames("game-container")}>
          {!showSettingTool && !showLoading && (
            <DraggableIcon showTool={() => showTool(true)} />
          )}
          <div
            id="gameVideo"
            style={{
              width: "100%",
              height: "100%",
            }}
          ></div>
          {showLoading && (
            <div className="loadingDiv">
              <div className="animation-loading">
                <Lottie
                  style={{ width: "100%", height: "100%" }}
                  animationData={animationData}
                  loop={true}
                />
              </div>
              {/* <div className="animation-progress">
                <Progress
                  percent={50}
                  showInfo={false}
                  percentPosition={{
                    align: "start",
                    type: "inner",
                  }}
                  size={["100%", 20]}
                  strokeColor="#008746"
                />
              </div> */}
            </div>
          )}
          {showStatisticLog && !showLoading && (
            <StatisticsLog
              logBandWidth={logBandWidth}
              logBitrate={logBitrate}
              logFps={logFps}
              logServerFps={logServerFps}
              logNetWorkDelay={logNetWorkDelay}
              logPacketLossRate={logPacketLossRate}
            />
          )}
          {isCountdownActive && !showLoading && (
            <Countdown
              initialSeconds={300}
              isActive={isCountdownActive}
              onCountdownEnd={handleCountdownEnd}
            />
          )}
          {showSettingTool && !showLoading && (
            <SettingTool
              showLog={showLog}
              showStatisticLog={showStatisticLog}
              showTool={showTool}
              showVKeyboard={showVKeyboard}
              showWasd={showWasd}
              showVGamepad={showVGamepad}
              showW={showW}
              WLCG={WLCG}
              tool={tool}
              closeGame={confirm}
            />
          )}
          {showVirtualKeyboard && !showLoading && <VirtualKeyboard />}
          {showWasdController && !showLoading && <WasdController WLCG={WLCG} />}
          {showVirtualGamepad && !showLoading && <VirtualGamepad WLCG={WLCG} />}
        </div>
      )}
      {step === "error" && <GameError confirm={confirm} errorMsg={errorMsg} />}
    </div>
  );
}
