import React from "react";
import style from "./GameQueueInfo.module.scss";
import GreenButton from "./GreenButton";
import Spacer from "./Spacer";

const GameQueueInfo = ({ cancel, msg }) => {
  return (
    <div className={style.dialog}>
      <div className={style.msg}>{msg}</div>
      <Spacer height="20px" />
      <GreenButton width="200px" text="Cancel" onClick={cancel} />
    </div>
  );
};

export default GameQueueInfo;
