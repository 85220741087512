import React, { useEffect, useRef, useState } from "react";
import "./WasdController.scss";
import "./VirtualKeyboard.scss";
import leftMouseImage from "../assets/wasd/welink_game_mouse_button_left_on.png";
import rightMouseImage from "../assets/wasd/welink_game_mouse_button_right_on.png";
import nipplejs from "nipplejs";
import detectResize, { clearDetectDirection } from "../utils/detectResize";

const WasdController = ({ WLCG }) => {
  let mouseSwitch = useRef(null);
  let [x, setX] = useState(0);
  let [y, setY] = useState(0);
  let [mouseBtn, setMouseBtn] = useState("left");
  let nippleManager = null;
  let [mouseImgInfo, setMouseImgInfo] = useState({
    isShow: false,
    data: null,
    xHotspot: 0,
    yHotspot: 0,
  });
  let touchend = (e) => {
    const switchVal = mouseSwitch.current.dataset.switch;
    const result = switchVal === "left" ? "right" : "left";
    mouseSwitch.current.dataset.switch = result;
    const switchBgImg = result === "left" ? leftMouseImage : rightMouseImage;
    mouseSwitch.current.style.backgroundImage = `url(${switchBgImg})`;
    setMouseBtn(result);
    if (e.cancelable) e.preventDefault();
  };

  let hanldeUpdateCursor = () => {
    const wrapper = document.querySelector(".wl-plugin-vkm");
    const mouse = document.querySelector(".wl-plugin-vkm-mouse-img");
    if (WLCG.configs.rotate && wrapper) {
      const wrapperWidth = wrapper.getBoundingClientRect().width;
      mouse.style.left = `${y}px`;
      mouse.style.top = `${wrapperWidth - x}px`;
    } else {
      mouse.style.left = `${x + mouseImgInfo.xHotspot}px`;
      mouse.style.top = `${y + mouseImgInfo.yHotspot}px`;
    }
    if (mouseImgInfo) {
      mouseImgInfo.data && (mouse.src = mouseImgInfo.data);
      mouse.style.display = mouseImgInfo.isShow ? "block" : "none";
    }
  };
  let setOnCursorData = () => {
    WLCG.onCursorData = (isShow, data, xHotspot, yHotspot) => {
      setMouseImgInfo({
        isShow,
        data,
        xHotspot: xHotspot || 0,
        yHotspot: yHotspot || 0,
      });
      hanldeUpdateCursor();
    };
  };

  let getRealXYValue = (e) => {
    const wrapper = document.querySelector(`.wl-plugin-vkm-mouse`);
    const { left, top } = wrapper.getBoundingClientRect();
    const targetEvent = e.changedTouches.item(0);
    const xValue = targetEvent.pageX - left;
    const yValue = targetEvent.pageY - top;
    setX(xValue);
    setY(yValue);
    hanldeUpdateCursor();
    return { xValue, yValue };
  };
  let getBtnValue = () => {
    return mouseBtn === "left" ? WLCG.MOUSE_LBUTTON : WLCG.MOUSE_RBUTTON;
  };
  let handleTouchMove = () => {
    setOnCursorData();
    const wrapper = document.querySelector(`.wl-plugin-vkm-mouse`);
    const WelinkGameAudio = document.querySelector("#WelinkGameAudio");
    wrapper.ontouchstart = (e) => {
      if (WelinkGameAudio && WelinkGameAudio.muted) {
        WelinkGameAudio.muted = false;
      }
      const { xValue, yValue } = getRealXYValue(e);

      WLCG.onCustomMouseEvent(getBtnValue(), WLCG.KEY_DOWN, xValue, yValue);
      e.preventDefault();
    };
    // move
    wrapper.ontouchmove = (e) => {
      const { xValue, yValue } = getRealXYValue(e);

      WLCG.onCustomMouseEvent(WLCG.MOUSE_MOVE, null, xValue, yValue);
      e.preventDefault();
    };
    // up
    wrapper.ontouchend = (e) => {
      const { xValue, yValue } = getRealXYValue(e);

      WLCG.onCustomMouseEvent(getBtnValue(), WLCG.KEY_UP, xValue, yValue);
      e.preventDefault();
    };

    // switch
    const mouseSwitch = document.querySelector(".wl-plugin-vkm-mouse-switch");
    mouseSwitch.style.background = `url(${leftMouseImage})`;
    mouseSwitch.style.backgroundSize = "cover";

    mouseSwitch.addEventListener("touchstart", (e) => {
      e.preventDefault();
    });
    mouseSwitch.addEventListener("touchend", (e) => {
      const switchVal = mouseSwitch.getAttribute("data-switch");
      const result = switchVal === "left" ? "right" : "left";

      mouseSwitch.setAttribute("data-switch", result);
      const switchBgImg = result === "left" ? leftMouseImage : rightMouseImage;
      mouseSwitch.style.background = `url(${switchBgImg})`;
      mouseSwitch.style.backgroundSize = "cover";
      setMouseBtn(result);

      if (e.cancelable) e.preventDefault();
    });
  };

  let handleKeyboard = () => {
    const circleKeyboardList = document.querySelectorAll(
      ".wl-plugin-vkm-circle",
    );
    circleKeyboardList.forEach((circleItem) => {
      const left = circleItem.getAttribute("left");
      const top = circleItem.getAttribute("top");
      if (left) {
        circleItem.style.left = `${left}px`;
      } else {
        circleItem.style.right = `${circleItem.getAttribute("right")}px`;
      }
      if (top) {
        circleItem.style.top = `${top}px`;
      } else {
        circleItem.style.bottom = `${circleItem.getAttribute("bottom")}px`;
      }
      circleItem.addEventListener("touchstart", (e) => {
        // e.preventDefault();
        const keycode = circleItem.getAttribute("data-keycode");
        if (["mouseWheelUp", "mouseWheelDown"].includes(keycode)) return;
        WLCG.onKeyBoardEvent(Number(keycode), WLCG.KEY_DOWN);
      });

      circleItem.addEventListener("touchend", (e) => {
        const keycode = circleItem.getAttribute("data-keycode");

        if (keycode === "mouseWheelUp") {
          WLCG.onCustomMouseEvent(WLCG.MOUSE_MWHEELUP, WLCG.KEY_UP, x, y);
        } else if (keycode === "mouseWheelDown") {
          WLCG.onCustomMouseEvent(WLCG.MOUSE_MWHEELDOWN, WLCG.KEY_UP, x, y);
        } else {
          WLCG.onKeyBoardEvent(Number(keycode), WLCG.KEY_UP);
        }
        // e.preventDefault();
      });
    });
  };

  let handleWASD = () => {
    const wasdNipple = document.querySelector(".wasd-nipple");
    if (!wasdNipple) {
      clearDetectDirection();
      return;
    }
    if (nippleManager) {
      nippleManager.destroy();
    }

    const screenWidth = window.innerWidth,
      screenHeight = window.innerHeight;
    if (screenWidth < screenHeight) {
      wasdNipple.className = "wasd-nipple wasd-nipple-isRotate";
    } else {
      wasdNipple.className = "wasd-nipple";
    }

    const nippleOptions = {
      zone: wasdNipple,
      mode: "static",
      color: "white",
      size: 100,
      position: { left: "50%", bottom: "50%" },
    };

    const rotateMap = {
      right: "up",
      left: "down",
      up: "left",
      down: "right",
    };

    const wasdMap = {
      up: "W",
      down: "S",
      left: "A",
      right: "D",
    };

    const keyCodeMap = {
      W: 87,
      S: 83,
      A: 65,
      D: 68,
    };

    const wasdDownHash = {};

    nippleManager = nipplejs.create(nippleOptions);

    nippleManager.on("move", (evt, data) => {
      let { direction } = data;
      if (!direction) return;
      direction = JSON.parse(JSON.stringify(direction));
      if (WLCG.configs.rotate) {
        direction.x = rotateMap[direction.x];
        direction.y = rotateMap[direction.y];
        direction.angle = rotateMap[direction.angle];
      }

      const directionX = wasdMap[direction.x];
      const directionY = wasdMap[direction.y];

      Object.keys(wasdDownHash).forEach((k) => {
        if (![directionX, directionY].includes(k)) {
          delete wasdDownHash[k];
          WLCG.onKeyBoardEvent(keyCodeMap[k], WLCG.KEY_UP);
        }
      });

      const directionAngle = wasdMap[direction.angle];
      if (!wasdDownHash[directionAngle]) {
        wasdDownHash[directionAngle] = 1;
        WLCG.onKeyBoardEvent(keyCodeMap[directionAngle], WLCG.KEY_DOWN);
      }
    });

    nippleManager.on("end", (evt, data) => {
      Object.keys(wasdDownHash).forEach((k) => {
        delete wasdDownHash[k];
        WLCG.onKeyBoardEvent(keyCodeMap[k], WLCG.KEY_UP);
      });
    });
  };

  useEffect(() => {
    handleKeyboard();
    handleTouchMove();
    handleWASD();

    // detectResize(() => {
    //     handleWASD();
    //   }, 200)
  }, []);
  return (
    <div className="wl-plugin-vkm">
      <div className="wl-plugin-vkm-mouse"></div>
      <div className="wasd-nipple"></div>
      <img className="wl-plugin-vkm-mouse-img" alt="#" />
      <div className="wl-plugin-vkm-circle l25 t50" data-keycode="27">
        ESC
      </div>
      <div className="wl-plugin-vkm-circle l25 t100" data-keycode="9">
        TAB
      </div>
      <div className="wl-plugin-vkm-circle l150 b15" data-keycode="17">
        Ctrl
      </div>
      <div
        className="wl-plugin-vkm-circle l195 b15"
        data-keycode="mouseWheelUp"
      ></div>
      <div className="wl-plugin-vkm-circle r225 b15" data-keycode="32">
        Space
      </div>
      <div
        className="wl-plugin-vkm-circle r170 b15"
        data-keycode="mouseWheelDown"
      ></div>
      <div className="wl-plugin-vkm-circle r130 b140" data-keycode="90">
        Z
      </div>
      <div className="wl-plugin-vkm-circle r78 b164" data-keycode="89">
        Y
      </div>
      <div className="wl-plugin-vkm-circle r20 b180" data-keycode="88">
        X
      </div>
      <div
        style={{ backgroundImage: `url(${leftMouseImage})` }}
        ref={mouseSwitch}
        onTouchStart={(e) => e.preventDefault()}
        onTouchEnd={touchend}
        className="wl-plugin-vkm-mouse-switch"
        data-switch="left"
      ></div>
    </div>
  );
};

export default WasdController;
