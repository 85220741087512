const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    const args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  };
};

let listener = null;

const resizeListener = (cb) => {
  listener = debounce(() => {
    cb();
  }, 200);
};

export const clearDetectDirection = () => {
  window.removeEventListener("resize", listener);
};

export default (cb) => {
  resizeListener(cb);
  window.addEventListener("resize", listener);
};
