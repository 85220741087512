import React from "react";
import Draggable from "react-draggable";
import icon from "../assets/img/settingicon.svg";
import "./DraggableIcon.scss";

const DraggableIcon = ({ showTool }) => {
  let isDragging = false;

  const handleDrag = () => {
    isDragging = true;
  };
  const handleStop = () => {
    setTimeout(() => (isDragging = false), 0);
  };

  const handleClick = (event) => {
    if (isDragging) {
      return;
    }
    showTool();
    event.stopPropagation();
  };
  return (
    <Draggable onDrag={handleDrag} onStop={handleStop}>
      <div onClick={handleClick} onTouchStart={handleClick} className="dragDiv">
        <img src={icon} draggable="false" alt="" />
      </div>
    </Draggable>
  );
};

export default DraggableIcon;
