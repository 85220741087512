import React from "react";
import style from "./GameError.module.scss";
import GreenButton from "../components/GreenButton";
import Spacer from "./Spacer";

const GameError = ({ confirm, errorMsg }) => {
  return (
    <div className={style.dialog}>
      <div className={style.errorMsg}>{errorMsg}</div>
      <Spacer height="20px" />
      <GreenButton width="200px" text="Confirm" onClick={confirm} />
    </div>
  );
};

export default GameError;
