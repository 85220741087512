import React, { useState, useEffect } from "react";
import styles from "./Countdown.module.scss";

const Countdown = ({ initialSeconds, isActive, onCountdownEnd }) => {
  const [seconds, setSeconds] = useState(initialSeconds);
  useEffect(() => {
    if (!isActive) return;
    if (seconds > 0) {
      const interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(interval);
    } else if (seconds === 0) {
      if (onCountdownEnd) {
        onCountdownEnd();
      }
    }
  }, [isActive, seconds, onCountdownEnd]);

  return (
    <div className={styles.CountdownContent}>
      <div className={styles.seconds}>
        Trial remaining {seconds > 0 ? `${seconds} s` : "0"}
      </div>
    </div>
  );
};
export default Countdown;
