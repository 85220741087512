/**
 * Replace with your configuration
 */
import videoPosterImg from "../assets/img/game_cover.jpg";

export const hostUrl = "https://paas-abroad.well-linktech.com";
const config = {
  // Tenant key provided by Vlink
  // tenantKey: 'WQmm0DxR3DXWUNeVtIg6jp22HpO7tg1ccngrTSCZR/DwNg1kVzKO6MfcHyh6uEoUkY8eSycsjRHgLp+osjudcymjyHR8aWl7cNEz8BosqaWMVjVfrGivbjt/8SLwQUk4Bcs09UlZ5RgSwm5F7o4AoA==',
  tenantKey: "1830518206268313601",
  // For security reasons, it is recommended to move the signature logic to the server-side instead of keeping it in the web code, in order to prevent the leakage of the signature key.
  // secret: '***',
  secret: "c597c43d0acdf848a134efa0969d51c1",
  // Current game deployment environment (replace when deploying online, provided by Vlink)
  IspUrl: hostUrl,
  // Default video placeholder image
  videoPoster: videoPosterImg,
  // Game ID for testing or scheduling purposes
  gameId: "1830518628324347906100048",
  // Node ID for node testing, can be omitted for scheduling purposes, choose the optimal node through testing
  nodeId: "2001602",
  // Enable clipboard synchronization, default is disabled, requires in-game support
  enableClipBoard: true,
  // Keyboard shortcut to toggle mouse lock state
  mouseShortcut: null,
  // Toggle mouse lock state
  lockPoint: true,
};
export default config;
