import React, { useState, useEffect, useRef } from "react";
import style from "./SettingTool.module.scss";
import { Dropdown } from "antd";
import "./SettingTool.scss";
import Tool from "../assets/img/svgs/tool.svg";
import VirtualKeyboard from "../assets/img/svgs/virtualkeyboard.svg";
import VirtualMouse from "../assets/img/svgs/wasd.svg";
import VirtualHand from "../assets/img/svgs/virtualhand.svg";
// import write from "../assets/img/svgs/write.svg";
import Hd from "../assets/img/svgs/hd.svg";
import Network from "../assets/img/svgs/network.svg";
import Fullscreen from "../assets/img/svgs/fullscreen.svg";
import Kaiguan from "../assets/img/svgs/kaiguan.svg";
import Open from "../assets/img/svgs/open.svg";
import Close from "../assets/img/svgs/close.svg";
import { bitRateLevelMap } from "../utils/constants";
import classNames from "classnames";

const SettingTool = ({
  showLog,
  showTool,
  tool,
  showVKeyboard,
  showWasd,
  showVGamepad,
  // showW,
  showStatisticLog,
  WLCG,
  closeGame,
}) => {
  const toolRef = useRef(null);
  const items = [
    {
      label: (
        <div className="iconDiv" onClick={showVKeyboard}>
          <img className="icon" src={VirtualKeyboard} alt="" />
          <span className={style.text}>Virtual keyboard</span>
        </div>
      ),
      key: 0,
    },
    {
      label: (
        <div className="iconDiv" onClick={showWasd}>
          <img className="icon" src={VirtualMouse} alt="" />
          <span className={style.text}>Virtual mouse</span>
        </div>
      ),
      key: 1,
    },
    {
      label: (
        <div className="iconDiv" onClick={showVGamepad}>
          <img className="icon" src={VirtualHand} alt="" />
          <span className={style.text}>Virtual gamepad</span>
        </div>
      ),
      key: 2,
    },
    // {
    //   label: (
    //     <div className="iconDiv" onClick={showW}>
    //       <img className="icon" src={write} alt="" />
    //       <span className={style.text}>Enter non-English</span>
    //     </div>
    //   ),
    //   key: "3",
    // },
  ];

  let [picture, setPicture] = useState("1-1");
  let setBitrate = (index, key) => {
    WLCG.setBitrateRange(
      bitRateLevelMap[index].lowerLimit,
      bitRateLevelMap[index].upperLimit,
    );
    setPicture(key);
  };
  const items2 = [
    {
      label: (
        <div
          onClick={() => {
            setBitrate(1, "1-1");
          }}
        >
          <span className={style.text}>Ultra High</span>
        </div>
      ),
      key: "1-1",
    },
    {
      label: (
        <div
          onClick={() => {
            setBitrate(2, "1-2");
          }}
        >
          <span className={style.text}>High</span>
        </div>
      ),
      key: "1-2",
    },
    {
      label: (
        <div
          onClick={() => {
            setBitrate(3, "1-3");
          }}
        >
          <span className={style.text}>Medium</span>
        </div>
      ),
      key: "1-3",
    },
    {
      label: (
        <div
          onClick={() => {
            setBitrate(4, "1-4");
          }}
        >
          <span className={style.text}>Low-Med</span>
        </div>
      ),
      key: "1-4",
    },
    {
      label: (
        <div
          onClick={() => {
            setBitrate(5, "1-5");
          }}
        >
          <span className={style.text}>Low</span>
        </div>
      ),
      key: "1-5",
    },
  ];

  const items3 = [
    {
      label: (
        <div className="iconDiv" onClick={() => showLog(true)}>
          <img className="icon" src={Open} alt="" />
          <span className={style.text}>Open</span>
        </div>
      ),
      key: true,
    },
    {
      label: (
        <div className="iconDiv" onClick={() => showLog(false)}>
          <img className="icon" src={Close} alt="" />
          <span className={style.text}>Close</span>
        </div>
      ),
      key: false,
    },
  ];

  // Close toolbox
  const handleClickOutside = (event) => {
    if (toolRef.current && !toolRef.current.contains(event.target)) {
      showTool();
    }
  };

  // Full screen
  const [isFullscreen, setIsFullscreen] = useState(false);

  const handleFullscreenChange = () => {
    if (document.fullscreenElement) {
      setIsFullscreen(true);
    } else {
      setIsFullscreen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  const enterFullscreen = () => {
    const element = document.documentElement;
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      // Firefox
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      // Chrome, Safari, Opera
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      // IE/Edge
      element.msRequestFullscreen();
    }
  };

  const exitFullscreen = () => {
    if (document.fullscreenElement) {
      // Ensure the document is in fullscreen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        // Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        // Chrome, Safari, Opera
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        // IE/Edge
        document.msExitFullscreen();
      }
    }
  };

  const toggleFullscreen = () => {
    if (isFullscreen) {
      exitFullscreen();
    } else {
      enterFullscreen();
    }
  };
  // useEffect(() => {
  //   console.log(tool, "tool");
  // }, [tool]);
  return (
    <div className={classNames(style.SettingContent)}>
      <div ref={toolRef} className={style.SettingContentList}>
        <div className={style.SettingContentListItem} id="tool">
          <Dropdown
            overlayClassName="setting-tool"
            menu={{
              items,
              selectable: true,
              multiple: false,
              selectedKeys: [`${tool}`],
            }}
            trigger={["click"]}
            getPopupContainer={() => document.getElementById("tool")}
          >
            <span>
              <img className="icon" src={Tool} alt="" />
              <span className="iconTitle">Tools</span>
            </span>
          </Dropdown>
        </div>
        <div className={style.SettingContentListItem} id="picture">
          <Dropdown
            overlayClassName="setting-tool"
            menu={{
              items: items2,
              selectable: true,
              selectedKeys: [`${picture}`],
            }}
            trigger={["click"]}
            getPopupContainer={() => document.getElementById("picture")}
          >
            <span>
              <img className="icon" src={Hd} alt="" />
              <span>Graphics</span>
            </span>
          </Dropdown>
        </div>
        <div className={style.SettingContentListItem} id="log">
          <Dropdown
            overlayClassName="setting-tool"
            // overlayStyle={{ position: "absolute", top: "36px", left: "0" }}
            menu={{
              items: items3,
              selectable: true,
              selectedKeys: [`${showStatisticLog}`],
            }}
            trigger={["click"]}
            getPopupContainer={() => document.getElementById("log")}
          >
            <span>
              <img className="icon" src={Network} alt="" />
              <span>Network state</span>
            </span>
          </Dropdown>
        </div>
        <div className={style.SettingContentListItem}>
          <span onClick={toggleFullscreen}>
            <img className="icon" src={Fullscreen} alt="" />
            <span>Full screen</span>
          </span>
        </div>
        <div className={style.SettingContentListItem}>
          <img
            onClick={() => {
              showTool(false);
              closeGame();
            }}
            className="icon"
            src={Kaiguan}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default SettingTool;
